import { useTranslation } from "react-i18next";

import {
  BuildIcon,
  Button,
  Flex,
  HighlightIcon,
  HorizontalLine,
  LocalShippingIcon,
  Paper,
  RowItem,
  Spinner,
  UndoIcon,
} from "@vapaus/ui-v2";

import { useCurrencyFormat } from "../../../../../../../../../packages/i18n/src/hooks/";
import { PayLaterButton } from "../../../RedeemReturnSharedComponents/PayLaterButton";
import { PayNowButton } from "../../../RedeemReturnSharedComponents/PayNowButton";
import { WizardPageViewLayout } from "../../../WizardPageViewLayout";
import { useReturnProcessContext } from "../../context/ReturnProcessContext";
import { useVapausSelfReturnBikeCenterAddress } from "../../hooks/useVapausSelfReturnBikeCenterAddress";
import { SummaryItemRow } from "./components/SummaryItemRow";
import { usePayForReturn } from "./hooks/usePayForReturn";
import { usePaymentSummary } from "./hooks/usePaymentSummary";

export function PaymentSummaryView() {
  const { t } = useTranslation();
  const { pickupAddressDetails, returnMethod, currency } =
    useReturnProcessContext();
  const bikeCenterAddress = useVapausSelfReturnBikeCenterAddress();
  const formatCurrency = useCurrencyFormat(currency);

  const {
    returnFee,
    accessories,
    totalAccessoriesPrice,
    overspentMaintenanceBudget,
    deliveryFee,
    totalPaymentAmount,
    isLoading: isLoadingSummary,
  } = usePaymentSummary();

  const {
    isLoading: isLoadingPayment,
    handlePayNow,
    handlePayLater,
    onConfirmAndClose,
  } = usePayForReturn();

  const title = totalPaymentAmount
    ? t("userV2:returnProcess.paymentSummary.title")
    : t("userV2:returnProcess.paymentSummary.titleNoPayment");

  const description = totalPaymentAmount
    ? t("userV2:returnProcess.paymentSummary.description")
    : t("userV2:returnProcess.paymentSummary.descriptionNoPayment");

  return (
    <WizardPageViewLayout title={title} description={description}>
      <Paper shadow="md">
        {isLoadingSummary ? (
          <Spinner />
        ) : (
          <Flex direction="column" gap="lg">
            {returnFee ? (
              <SummaryItemRow
                icon={UndoIcon}
                title={t(
                  "userV2:returnProcess.paymentSummary.earlyReturnFee.title",
                )}
                description={t(
                  "userV2:returnProcess.paymentSummary.earlyReturnFee.description",
                )}
                price={returnFee}
              />
            ) : null}
            {accessories ? (
              <SummaryItemRow
                icon={HighlightIcon}
                title={t(
                  "userV2:returnProcess.paymentSummary.accessoriesToRedeem.title",
                )}
                description={accessories.map((accessory) => ({
                  descriptionItem: accessory.description,
                  itemPrice: accessory.price,
                }))}
                price={totalAccessoriesPrice}
              />
            ) : null}
            {overspentMaintenanceBudget ? (
              <SummaryItemRow
                icon={BuildIcon}
                title={t(
                  "userV2:returnProcess.paymentSummary.overspentMaintenanceBudget.title",
                )}
                description={t(
                  "userV2:returnProcess.paymentSummary.overspentMaintenanceBudget.description",
                )}
                price={overspentMaintenanceBudget}
              />
            ) : null}
            {returnMethod === "pickup" ? (
              <SummaryItemRow
                icon={LocalShippingIcon}
                title={t("userV2:returnProcess.paymentSummary.pickup.title")}
                description={[
                  { descriptionItem: pickupAddressDetails.address || "" },
                  {
                    descriptionItem: `${pickupAddressDetails.postalCode} ${pickupAddressDetails.city}`,
                  },
                  { descriptionItem: pickupAddressDetails.phoneNumber || "" },
                ]}
                price={deliveryFee ?? 0}
              />
            ) : (
              <SummaryItemRow
                icon={LocalShippingIcon}
                title={t(
                  "userV2:returnProcess.paymentSummary.selfReturn.title",
                )}
                description={[
                  { descriptionItem: bikeCenterAddress.name },
                  { descriptionItem: bikeCenterAddress.addressLine1 },
                  { descriptionItem: bikeCenterAddress.addressLine2 },
                ]}
                price={t("userV2:returnProcess.paymentSummary.selfReturn.free")}
              />
            )}
            <HorizontalLine marginBottom="none" marginTop="none" />
            {totalPaymentAmount > 0 ? (
              <>
                <RowItem
                  label={t("userV2:returnProcess.paymentSummary.total")}
                  value={formatCurrency(totalPaymentAmount)}
                  labelIsBold
                  valueIsBold
                />
                <PayNowButton
                  isLoading={isLoadingPayment}
                  handlePayNow={handlePayNow}
                  price={totalPaymentAmount}
                />
                <Flex fullWidth justify="center">
                  <PayLaterButton
                    handlePayLater={handlePayLater}
                    isLoading={isLoadingPayment}
                  />
                </Flex>
              </>
            ) : (
              <Button
                onClick={onConfirmAndClose}
                isLoading={isLoadingPayment}
                fullWidth
              >
                {t("userV2:returnProcess.paymentSummary.confirmAndClose")}
              </Button>
            )}
          </Flex>
        )}
      </Paper>
    </WizardPageViewLayout>
  );
}
