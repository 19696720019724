import { useTranslation } from "react-i18next";

import { Carousel, useBreakpoints } from "@vapaus/ui-v2";

import {
  SpecialDealCard,
  SpecialDealCardProps,
} from "./components/SpecialDealCard";
import activeCannondaleBgImage from "./images/active-cannondale-precycled.png";
import inseraLogoImage from "./images/insera-logo.png";
import activeCannondaleLogoImage from "./images/precycled-logo.png";
import inseraBgImage from "./images/special-deal-insera.jpg";

export function SpecialDeals() {
  const { isXs } = useBreakpoints();
  const { t } = useTranslation();

  const specialDealItems: SpecialDealCardProps[] = [
    {
      id: "special-active-cannondale",
      title: "Active & Cannondale -20%",
      description: t(
        "userV2:getABike.specialDeals.activeCannondale.description",
      ),
      logoImage: activeCannondaleLogoImage,
      backgroundImage: activeCannondaleBgImage,
      link: "https://precycled.io/collections/all-new-bikes",
    },
    {
      id: "special-deals-insera",
      title: t("userV2:getABike.specialDeals.insera.title"),
      description: t("userV2:getABike.specialDeals.insera.description"),
      logoImage: inseraLogoImage,
      backgroundImage: inseraBgImage,
      link: "https://www.vapaus.io/tyontekijalle/pyoratarjoukset",
    },
  ];

  const renderItem = ({
    item,
    size,
  }: {
    item: SpecialDealCardProps;
    size: {
      height: number;
      width: number;
    };
  }) => (
    <SpecialDealCard
      id={item.id}
      title={item.title}
      description={item.description}
      backgroundImage={item.backgroundImage}
      logoImage={item.logoImage}
      link={item.link}
      size={size}
    />
  );

  return (
    <Carousel
      title={t("userV2:getABike.specialDeals.title")}
      data={specialDealItems}
      renderItem={renderItem}
      endPadding="sm"
      gap="md"
      maxHeight={293}
      itemsPerPage={isXs ? 1 : 2}
    />
  );
}
