import { sortBy } from "lodash-es";
import { useTranslation } from "react-i18next";

import {
  BenefitDefinitionCandidate,
  BenefitDefinitionUserRead,
  Currency,
  SupportedOrganisationCountries,
} from "@vapaus/api-codegen";
import { Flex, RowItem, Tooltip } from "@vapaus/ui-v2";

import { useCurrencyFormat } from "../../../../packages/i18n/src/hooks";

type BenefitDetailsPreviewProps = {
  benefit?: BenefitDefinitionCandidate | BenefitDefinitionUserRead;
};

export function BenefitDetailsPreview({ benefit }: BenefitDetailsPreviewProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(benefit?.currency ?? Currency.Eur);
  const isBenefitSwedish =
    benefit?.organisation?.country === SupportedOrganisationCountries.Se;

  const getMonthlyPayment = () => {
    const fixedValues = sortBy(benefit?.fixedMonthlyTaxableValues);

    if (fixedValues && fixedValues.length > 0) {
      return fixedValues.length === 1
        ? {
            value: `${formatCurrency(fixedValues[0])}`,
            tooltip: isBenefitSwedish
              ? t("userV2:benefitPreview.monthlyRentalFee.singleFixedValue", {
                  value: formatCurrency(fixedValues[0]),
                })
              : t("userV2:benefitPreview.monthlyPayment.singleFixedValue", {
                  value: formatCurrency(fixedValues[0]),
                }),
          }
        : {
            value: `${formatCurrency(fixedValues[0])}-${formatCurrency(
              fixedValues[fixedValues.length - 1],
            )}`,
            tooltip: isBenefitSwedish
              ? t("userV2:benefitPreview.monthlyRentalFee.multipleFixedValues")
              : t("userV2:benefitPreview.monthlyPayment.multipleFixedValues"),
          };
    }

    return {
      value: `${formatCurrency(
        benefit?.minimumMonthlyTaxableValue,
      )}-${formatCurrency(benefit?.maximumMonthlyTaxableValue)}`,
      tooltip: isBenefitSwedish
        ? t("userV2:benefitPreview.monthlyRentalFee.valueRange")
        : t("userV2:benefitPreview.monthlyPayment.valueRange"),
    };
  };

  return (
    <Flex direction="column">
      <RowItem
        label={t("userV2:benefitPreview.maxBikePackage.label")}
        value={
          <Flex align="center">
            <span>{formatCurrency(benefit?.maximumTaxablePackagePrice)}</span>
            <Tooltip>
              {t("userV2:benefitPreview.maxBikePackage.tooltip")}
            </Tooltip>
          </Flex>
        }
      />
      <RowItem
        label={t("userV2:benefitPreview.downPayment.label")}
        value={
          <Flex align="center">
            <span>
              {benefit?.isDownpaymentAllowed
                ? t("userV2:benefitPreview.downPayment.allowed")
                : t("userV2:benefitPreview.downPayment.notAllowed")}
            </span>
            <Tooltip>
              {benefit?.isDownpaymentAllowed
                ? t("userV2:benefitPreview.downPayment.tooltip.allowed")
                : t("userV2:benefitPreview.downPayment.tooltip.notAllowed")}
            </Tooltip>
          </Flex>
        }
      />
      {benefit?.isDownpaymentAllowed ? (
        <RowItem
          label={t("userV2:benefitPreview.maxPriceWithDownPayment.label")}
          value={
            <Flex align="center">
              <span>{formatCurrency(benefit.maximumTotalPackagePrice)}</span>
              <Tooltip>
                {t("userV2:benefitPreview.maxPriceWithDownPayment.tooltip")}
              </Tooltip>
            </Flex>
          }
        />
      ) : null}
      <RowItem
        label={t("userV2:benefitPreview.leasingPeriod.label")}
        value={
          <Flex align="center">
            <span>
              {t("userV2:benefitPreview.leasingPeriod.value", {
                minPeriod: benefit?.minimumLeasingPeriod,
                maxPeriod: benefit?.maximumLeasingPeriod,
              })}
            </span>
            <Tooltip>
              {t("userV2:benefitPreview.leasingPeriod.tooltip")}
            </Tooltip>
          </Flex>
        }
      />
      <RowItem
        label={
          isBenefitSwedish
            ? t("userV2:benefitPreview.monthlyRentalFee.label")
            : t("userV2:benefitPreview.monthlyPayment.label")
        }
        value={
          <Flex align="center">
            <span>{getMonthlyPayment().value}</span>
            <Tooltip>{getMonthlyPayment().tooltip}</Tooltip>
          </Flex>
        }
      />
    </Flex>
  );
}
