import React, { ReactElement, ReactNode, useState } from "react";

import { TFunction } from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  BikeBenefitContractCancellationReason,
  BikeBenefitContractRead,
  BikeBenefitContractState,
  BikeBenefitOrderRead,
  BikeBenefitOrderState,
  RedemptionProcessRead,
  ReturnProcessRead,
} from "@vapaus/api-codegen";
import { Hero } from "@vapaus/common-v2";
import {
  BuildIcon,
  Button,
  Flex,
  FullPageModal,
  OrderApproveIcon,
  PaletteVariants,
  PedalBikeIcon,
  ReceiptIcon,
  TabRowItemProps,
  TextButton,
  Tooltip,
  Typography,
} from "@vapaus/ui-v2";

import { useDateFormat } from "../../../../../../../packages/i18n/src/hooks";
import { useGetBenefitDefinition } from "../../../../api/benefitDefinitions";
import { useGetRedemptionProcess } from "../../../../api/redemptionProcess";
import { useGetReturnProcess } from "../../../../api/returnProcess";
import { useGetShop } from "../../../../api/shop";
import { OrderStatus } from "../../../../components/OrderStatus";
import { getIsContractEndingSoon } from "../../../../utils/getIsContractEndingSoon";
import { ContractSplit } from "../ContractSplit";
import { RedeemReturnCTAButtons } from "../RedeemReturnSharedComponents/RedeemReturnCTAButtons";
import { ReturnChecklistPage } from "../ReturnChecklistPage";
import { HeroNavigation } from "./HeroNavigation";
import { InvoicesButtonWithScrollDown } from "./InvoicesButtonWithScrollDown";

interface MyBikeHeroProps {
  order: BikeBenefitOrderRead;
  contract?: BikeBenefitContractRead;
}

export function MyBikeHero({ order, contract }: MyBikeHeroProps) {
  const { t } = useTranslation();
  const formatDate = useDateFormat();
  const [isReturnChecklistModalOpen, setIsReturnChecklistModalOpen] =
    useState<boolean>(false);

  const { data: shop } = useGetShop(contract ? undefined : order.shopId);
  const { data: benefitDefinition } = useGetBenefitDefinition(
    contract?.benefitDefinitionId ?? order?.benefitDefinitionId,
  );
  const { data: redemptionProcess } = useGetRedemptionProcess(
    contract?.currentRedemptionProcessId,
  );
  const { data: returnProcess } = useGetReturnProcess(
    contract?.currentReturnProcessId,
  );

  const handleOpenReturnChecklistModal = () =>
    setIsReturnChecklistModalOpen(true);

  const heroProps = getMyBikeHeroProps(
    t,
    formatDate,
    order,
    handleOpenReturnChecklistModal,
    contract,
    redemptionProcess,
    returnProcess,
  );

  return (
    <>
      <Hero
        navigationHeader={
          <HeroNavigation
            background={heroProps.background}
            benefitDefinition={benefitDefinition}
            contract={contract}
            returnProcess={returnProcess}
            handleOpenReturnChecklistModal={handleOpenReturnChecklistModal}
          />
        }
        beforeContent={
          <Flex direction="column" align="center" gap="sm">
            <OrderStatus order={order} contract={contract} size="large" />
            <Flex gap="xs">
              <Typography weight="bold">
                {contract ? benefitDefinition?.organisation.name : shop?.name}
              </Typography>
              <Typography weight="bold">·</Typography>
              <Tooltip
                trigger={
                  <Typography weight="bold">{order.vapausCode}</Typography>
                }
              >
                {t("commonV2:vapausCode")}
              </Tooltip>
            </Flex>
          </Flex>
        }
        title={`${order.bike.bikeBrand.name} ${order.bike.model}`}
        {...heroProps}
        tabs={getTabs(t, order, contract)}
      />
      {returnProcess ? (
        <FullPageModal
          isOpen={isReturnChecklistModalOpen}
          onClose={() => setIsReturnChecklistModalOpen(false)}
        >
          <ReturnChecklistPage>
            <Button
              onClick={() => setIsReturnChecklistModalOpen(false)}
              fullWidth
            >
              {t("commonV2:close")}
            </Button>
          </ReturnChecklistPage>
        </FullPageModal>
      ) : null}
    </>
  );
}

interface GetMyBikeHeroPropsReturn {
  description: ReactElement | string;
  background: PaletteVariants;
  afterContent?: ReactNode;
}

function getMyBikeHeroProps(
  t: TFunction,
  formatDate: ReturnType<typeof useDateFormat>,
  order: MyBikeHeroProps["order"],
  handleOpenReturnChecklistModal: () => void,
  contract?: MyBikeHeroProps["contract"],
  redemptionProcess?: RedemptionProcessRead,
  returnProcess?: ReturnProcessRead,
): GetMyBikeHeroPropsReturn {
  switch (contract?.state) {
    case BikeBenefitContractState.CancelledPendingPayment:
    case BikeBenefitContractState.EndedPendingPayment:
      return {
        description: t(
          "userV2:myBike.hero.contractWaitingForPaymentDescription",
        ),
        background: "secondary2.lighter",
        afterContent: (
          <Link to="billing" preventScrollReset>
            <Button>{t("userV2:myBike.hero.openInvoiceButton")}</Button>
          </Link>
        ),
      };
    case BikeBenefitContractState.Cancelled:
      return {
        description: getCancelledContractDescription(
          formatDate,
          contract,
          redemptionProcess,
          returnProcess,
        ),
        background: "common.white",
        afterContent: contract.cancellationReasonDescription ? (
          <Typography>{contract.cancellationReasonDescription}</Typography>
        ) : null,
      };
    case BikeBenefitContractState.Active: {
      const isEndingSoon = getIsContractEndingSoon(contract.endDate);
      return {
        description: isEndingSoon ? (
          t("userV2:myBike.hero.activeEndingSoonDescription")
        ) : (
          <Trans
            i18nKey="userV2:myBike.hero.activeDescription"
            values={{ date: formatDate(contract.leasingPeriodStartDate) }}
            components={{ b: <b /> }}
          />
        ),
        background: isEndingSoon ? "secondary3.lighter" : "secondary1.lighter",
        afterContent: (
          <Flex direction="column" gap="lg" align="center">
            {isEndingSoon ? (
              <RedeemReturnCTAButtons contract={contract} />
            ) : null}
            <ContractSplit contract={contract} />
          </Flex>
        ),
      };
    }
    case BikeBenefitContractState.Ended:
      return {
        description:
          redemptionProcess?.status === "finalised" ? (
            <Trans
              i18nKey="userV2:myBike.hero.endedAndRedeemedDescription"
              values={{
                date: formatDate(redemptionProcess.plannedRedemptionDate),
              }}
              components={{ b: <b /> }}
            />
          ) : returnProcess?.status === "completed" ? (
            <Trans
              i18nKey="userV2:myBike.hero.endedAndReturnedDescription"
              values={{
                date: formatDate(returnProcess.leaseEndDate),
              }}
              components={{ b: <b /> }}
            />
          ) : (
            <Trans
              i18nKey="userV2:myBike.hero.endedDescription"
              values={{ date: formatDate(contract.endDate) }}
              components={{ b: <b /> }}
            />
          ),
        background: "common.white",
      };
    case BikeBenefitContractState.Scheduled:
      return {
        description: (
          <Trans
            i18nKey="userV2:myBike.hero.scheduledDescription"
            values={{ date: formatDate(contract.leasingPeriodStartDate) }}
            components={{ b: <b /> }}
          />
        ),
        background: "secondary3.lighter",
      };
    case BikeBenefitContractState.ActivePendingCorrection:
      return {
        description: t("userV2:myBike.hero.pendingCorrectionDescription"),
        background: "secondary3.lighter",
        afterContent: <ContractSplit contract={contract} />,
      };
    case BikeBenefitContractState.ActivePendingRevision:
      return {
        description: t("userV2:myBike.hero.pendingRevisionDescription"),
        background: "secondary3.lighter",
        afterContent: <ContractSplit contract={contract} />,
      };
    case BikeBenefitContractState.ActivePendingRedemption:
      return {
        description: (
          <Trans
            i18nKey="userV2:myBike.hero.pendingRedemptionDescription"
            values={{
              date: formatDate(redemptionProcess?.plannedRedemptionDate),
            }}
            components={{ b: <b /> }}
          />
        ),
        background: "secondary3.lighter",
        afterContent: <InvoicesButtonWithScrollDown />,
      };
    case BikeBenefitContractState.ActivePendingReturn:
      return returnProcess?.pickupDetails != null
        ? {
            description: (
              <Trans
                i18nKey="userV2:myBike.hero.pendingReturnPickupDescription"
                components={{
                  1: <TextButton to="billing#focus">invoices</TextButton>,
                  2: (
                    <TextButton onClick={handleOpenReturnChecklistModal}>
                      prepare the bike
                    </TextButton>
                  ),
                }}
              />
            ),
            background: "secondary3.lighter",
            afterContent: <InvoicesButtonWithScrollDown />,
          }
        : {
            description: (
              <Trans
                i18nKey="userV2:myBike.hero.pendingReturnSelfReturnDescription"
                components={{
                  1: <TextButton to="billing#focus">invoices</TextButton>,
                  2: (
                    <Link
                      to="https://vapaus.typeform.com/tsp-return"
                      target="_blank"
                      rel="noreferrer"
                    >
                      book a return date
                    </Link>
                  ),
                  3: (
                    <TextButton onClick={handleOpenReturnChecklistModal}>
                      prepare your bike
                    </TextButton>
                  ),
                }}
              />
            ),
            background: "secondary3.lighter",
            afterContent: (
              <Flex>
                <InvoicesButtonWithScrollDown />
                <Link
                  to="https://vapaus.typeform.com/tsp-return"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button variant="outlined" size="small">
                    {t(
                      "userV2:myBike.hero.pendingReturnSelfReturnBookReturnDate",
                    )}
                  </Button>
                </Link>
              </Flex>
            ),
          };
    case BikeBenefitContractState.Invalidated:
      return {
        description: (
          <Trans
            i18nKey="userV2:myBike.hero.invalidatedDescription"
            values={{ date: formatDate(contract.updatedAt) }}
            components={{ b: <b /> }}
          />
        ),
        background: "common.white",
      };
  }

  switch (order.state) {
    case BikeBenefitOrderState.Draft:
      return {
        description: t("userV2:myBike.hero.draftDescription"),
        background: "secondary3.lighter",
      };
    case BikeBenefitOrderState.Cancelled:
      return {
        description: (
          <Trans
            i18nKey="userV2:myBike.hero.orderCancelledDescription"
            values={{ date: formatDate(order.updatedAt) }}
            components={{ b: <b /> }}
          />
        ),
        background: "common.white",
      };
    case BikeBenefitOrderState.ReadyForDelivery:
      return {
        description: (
          <Trans
            i18nKey="userV2:myBike.hero.readyForDeliveryDescription"
            values={{ date: formatDate(order.updatedAt) }}
            components={{ b: <b /> }}
          />
        ),
        background: "primary2.lighter",
      };
    case BikeBenefitOrderState.WaitingForPayment:
      return {
        description: t("userV2:myBike.hero.orderWaitingForPaymentDescription"),
        background: "secondary2.lighter",
        afterContent: <InvoicesButtonWithScrollDown />,
      };
  }

  return {
    description: t("userV2:myBike.hero.unknownDescription"),
    background: "neutral.lighter",
  };
}

function getCancelledContractDescription(
  formatDate: ReturnType<typeof useDateFormat>,
  contract: BikeBenefitContractRead,
  redemptionProcess?: RedemptionProcessRead,
  returnProcess?: ReturnProcessRead,
): string | ReactElement {
  switch (contract.cancellationReason) {
    case BikeBenefitContractCancellationReason.InsuranceAccident:
      return (
        <Trans
          i18nKey="userV2:myBike.hero.cancelledAccidentDescription"
          values={{ date: formatDate(contract.effectiveEndDate) }}
          components={{ b: <b /> }}
        />
      );
    case BikeBenefitContractCancellationReason.InsuranceTheft:
      return (
        <Trans
          i18nKey="userV2:myBike.hero.cancelledTheftDescription"
          values={{ date: formatDate(contract.effectiveEndDate) }}
          components={{ b: <b /> }}
        />
      );
    case BikeBenefitContractCancellationReason.MergerAcquisition:
      return (
        <Trans
          i18nKey="userV2:myBike.hero.cancelledMergerDescription"
          values={{ date: formatDate(contract.effectiveEndDate) }}
          components={{ b: <b /> }}
        />
      );
    case BikeBenefitContractCancellationReason.Redeemed:
      return (
        <Trans
          i18nKey="userV2:myBike.hero.cancelledRedeemedDescription"
          values={{
            date: formatDate(redemptionProcess?.plannedRedemptionDate),
          }}
          components={{ b: <b /> }}
        />
      );
    case BikeBenefitContractCancellationReason.Returned:
      return (
        <Trans
          i18nKey="userV2:myBike.hero.cancelledReturnedDescription"
          values={{ date: formatDate(returnProcess?.leaseEndDate) }}
          components={{ b: <b /> }}
        />
      );
    case BikeBenefitContractCancellationReason.Revised:
      return (
        <Trans
          i18nKey="userV2:myBike.hero.cancelledRevisedDescription"
          values={{ date: formatDate(contract.effectiveEndDate) }}
          components={{ b: <b /> }}
        />
      );
    default:
      return (
        <Trans
          i18nKey="userV2:myBike.hero.contractCancelledDescription"
          values={{ date: formatDate(contract.effectiveEndDate) }}
          components={{ b: <b /> }}
        />
      );
  }
}

function getTabs(
  t: TFunction,
  order: MyBikeHeroProps["order"],
  contract?: MyBikeHeroProps["contract"],
): Array<TabRowItemProps> {
  const tabs = [];

  if (
    order.state === BikeBenefitOrderState.ReadyForDelivery ||
    order.state === BikeBenefitOrderState.WaitingForPayment
  ) {
    tabs.push(
      {
        icon: PedalBikeIcon,
        label: t("userV2:myBike.tabs.order"),
        to: `../${order.id}`,
      },
      {
        icon: ReceiptIcon,
        label: t("userV2:myBike.tabs.billing"),
        to: "billing",
      },
    );
  } else if (contract) {
    tabs.push(
      {
        icon: OrderApproveIcon,
        label: t("userV2:myBike.tabs.contract"),
        to: `../${order.id}`,
      },
      {
        icon: BuildIcon,
        label: t("userV2:myBike.tabs.maintenance"),
        to: "maintenance",
      },
      {
        icon: PedalBikeIcon,
        label: t("userV2:myBike.tabs.orderDetails"),
        to: "bike-package",
      },
      {
        icon: ReceiptIcon,
        label: t("userV2:myBike.tabs.billing"),
        to: "billing",
      },
    );
  }
  return tabs;
}
