import { ReactElement } from "react";

import { TFunction } from "i18next";
import { Trans, useTranslation } from "react-i18next";

import {
  BenefitCancellationCoverage,
  BenefitDefinitionCancellationCoverage,
  BenefitDefinitionUserRead,
  BikeBenefitOrderRead,
  BikeBenefitOrderState,
  Plan,
  SupportedOrganisationCountries,
} from "@vapaus/api-codegen";
import { Checkbox } from "@vapaus/form";
import { Currency } from "@vapaus/generated";
import { Card, Flex, TextButton } from "@vapaus/ui-v2";

import { useCurrencyFormat } from "../../../../../../packages/i18n/src/hooks";
import { NameValueDescription } from "../../../components/NameValueDescription";

interface CancellationCoverageCardProps {
  order: BikeBenefitOrderRead;
  benefitDefinition?: BenefitDefinitionUserRead;
  onModalOpen: () => void;
}

export function CancellationCoverageCard({
  order,
  benefitDefinition,
  onModalOpen,
}: CancellationCoverageCardProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(
    benefitDefinition?.currency || Currency.EUR,
  );

  const termsLink = benefitDefinition?.currentBenefitDefinitionTerm?.content ? (
    <TextButton size="md" onClick={onModalOpen} children={null} />
  ) : (
    <TextButton
      size="md"
      to={
        benefitDefinition?.currentBenefitDefinitionTerm?.pdfObject?.publicUrl ??
        ""
      }
      target="_blank"
      children={null}
    />
  );

  return (
    <Card title={t("userV2:myBike.cancellationCoverageCard.title")}>
      <Flex direction="column" gap="xs">
        <NameValueDescription
          name={t("userV2:myBike.cancellationCoverageCard.name")}
          {...cancelationCoverage(
            t,
            formatCurrency,
            order,
            termsLink,
            benefitDefinition,
          )}
        />
        {order.state === BikeBenefitOrderState.Draft &&
          benefitDefinition &&
          benefitDefinition.cancellationCoverage ===
            BenefitDefinitionCancellationCoverage.NeverIncluded && (
            <Checkbox
              name="optionalCancellationCoverage"
              label={t(
                "userV2:myBike.cancellationCoverageCard.optional.inputLabel",
                {
                  amount: formatCurrency(
                    benefitDefinition.individualOnetimeCancellationCoverage,
                  ),
                },
              )}
            />
          )}
      </Flex>
    </Card>
  );
}

function cancelationCoverage(
  t: TFunction,
  formatCurrency: ReturnType<typeof useCurrencyFormat>,
  order: BikeBenefitOrderRead,
  termsLink: ReactElement,
  benefitDefinition?: BenefitDefinitionUserRead,
) {
  if (!benefitDefinition)
    return {
      value: "",
      description: "",
    };

  if (order.state !== BikeBenefitOrderState.Draft) {
    return order.cancellationCoverage === BenefitCancellationCoverage.No
      ? {
          value: t("userV2:myBike.cancellationCoverageCard.values.notIncluded"),
          description: t(
            "userV2:myBike.cancellationCoverageCard.notIncludedDescription",
          ),
        }
      : {
          value: t("userV2:myBike.cancellationCoverageCard.values.included"),
          description: (
            <Trans
              i18nKey={
                "userV2:myBike.cancellationCoverageCard.includedDescription"
              }
              components={{ a: termsLink }}
            />
          ),
        };
  }

  if (
    (benefitDefinition.plan === Plan.Custom ||
      benefitDefinition.plan === Plan.Legacy) &&
    order.fixedDownPaymentAmount
  ) {
    return {
      value: t("userV2:myBike.cancellationCoverageCard.values.notAvailable"),
      description: t(
        "userV2:myBike.cancellationCoverageCard.notAvailableWithDownPayment",
      ),
    };
  }

  if (
    benefitDefinition.cancellationCoverage ===
    BenefitDefinitionCancellationCoverage.AlwaysIncludedEmployee
  ) {
    return {
      value: t("userV2:myBike.cancellationCoverageCard.values.mandatory"),
      description:
        order.cancellationCoverage === BenefitCancellationCoverage.Employee ? (
          <Trans
            i18nKey={
              "userV2:myBike.cancellationCoverageCard.mandatoryEmployeeAlreadyInvoiced"
            }
            components={{ a: termsLink }}
          />
        ) : (
          <Trans
            i18nKey={"userV2:myBike.cancellationCoverageCard.mandatoryEmployee"}
            values={{
              amount: formatCurrency(
                benefitDefinition.individualOnetimeCancellationCoverage,
              ),
            }}
            components={{ a: termsLink }}
          />
        ),
    };
  }

  if (
    benefitDefinition.cancellationCoverage ===
    BenefitDefinitionCancellationCoverage.AlwaysIncludedEmployer
  ) {
    return {
      value: t("userV2:myBike.cancellationCoverageCard.values.included"),
      description: (
        <Trans
          i18nKey={"userV2:myBike.cancellationCoverageCard.includedEmployer"}
          components={{ a: termsLink }}
        />
      ),
    };
  }
  if (
    benefitDefinition.cancellationCoverage ===
    BenefitDefinitionCancellationCoverage.Free
  ) {
    return {
      value: t("userV2:myBike.cancellationCoverageCard.values.included"),
      description:
        benefitDefinition.organisation.country ===
        SupportedOrganisationCountries.Se ? (
          <Trans
            i18nKey={"userV2:myBike.cancellationCoverageCard.freeSweden"}
            components={{ a: termsLink }}
          />
        ) : (
          <Trans
            i18nKey={"userV2:myBike.cancellationCoverageCard.free"}
            components={{ a: termsLink }}
          />
        ),
    };
  }

  if (
    benefitDefinition.cancellationCoverage ===
    BenefitDefinitionCancellationCoverage.NeverIncluded
  ) {
    return {
      value: t("userV2:myBike.cancellationCoverageCard.values.optional"),
      description: (
        <Trans
          i18nKey={
            "userV2:myBike.cancellationCoverageCard.optional.description"
          }
          values={{
            amount: formatCurrency(
              benefitDefinition.individualOnetimeCancellationCoverage,
            ),
          }}
          components={{ a: termsLink }}
        />
      ),
    };
  }

  return {
    value: "",
    description: "",
  };
}
