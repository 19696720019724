import { useEffect, useRef, useState } from "react";

import {
  BikeBenefitOrderRead,
  BikeBenefitOrderState,
} from "@vapaus/api-codegen";
import {
  BenefitTermsModal,
  getOrderCalculationParams,
  getOrderTotal,
} from "@vapaus/common-v2";
import { Form } from "@vapaus/form";
import {
  useCurrentUser,
  useOrderCalculation,
  useSwedenOrderBenefitValuesCalculation,
} from "@vapaus/shared-api";
import { Flex } from "@vapaus/ui-v2";

import { useGetBenefitDefinition } from "../../../../api/benefitDefinitions";
import { useGetShop } from "../../../../api/shop";
import { BikeOrderCard } from "../BikeOrderCard";
import { CancellationCoverageCard } from "../CancellationCoverageCard";
import { DownPaymentCard } from "../DownPaymentCard";
import { LeasingCostsCard } from "../LeasingCostsCard";
import { MaintenanceCard } from "../MaintenanceCard";
import { ShopCard } from "../ShopCard";
import { ContractSummaryCard } from "./components/ContractSummaryCard/ContractSummaryCard";
import { LeasingContractConfirmCard } from "./components/LeasingContractConfirmCard";
import { MissingInfoModal } from "./components/MissingInfoModal";
import { MissingSSNModal } from "./components/MissingSSNModal";
import { useAcceptOrderForm } from "./hooks/useAcceptOrderForm";

interface TabPanelOrderProps {
  order: BikeBenefitOrderRead;
}

export function TabPanelOrder({ order }: TabPanelOrderProps) {
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isMissingInfoModalOpen, setIsMissingInfoModalOpen] = useState(false);
  const [isEIdentModalOpen, setIsEIdentModalOpen] = useState(false);
  const { data: shop } = useGetShop(order.shopId);
  const { data: benefitDefinition } = useGetBenefitDefinition(
    order.benefitDefinitionId,
  );
  const { formMethods, mutation } = useAcceptOrderForm(order);
  const { data: user } = useCurrentUser();
  const currentState = useRef<string>(order.state);

  useEffect(() => {
    if (currentState.current !== order.state) {
      // If the order state has changed this means that an action happened
      // like accepting the order.
      window.scrollTo(0, 0);
      currentState.current = order.state;
    }
  }, [order.state]);

  const orderCalculationParams = getOrderCalculationParams(
    order,
    benefitDefinition,
  );
  const { data: calculation } = useOrderCalculation(orderCalculationParams, {
    enabled: Boolean(benefitDefinition),
  });

  const { data: swedenSuggestedBenefitValues } =
    useSwedenOrderBenefitValuesCalculation(order, benefitDefinition);

  let missingUserInfoStatus: "e-ident" | "incomplete-profile" | undefined =
    undefined;
  if (!user?.ssn) missingUserInfoStatus = "e-ident";
  else if (
    !user?.firstName ||
    !user?.lastName ||
    !user?.address ||
    !user?.postCode ||
    !user?.city ||
    (benefitDefinition?.isEmployeeNumberMandatory && !user?.employeeNumber) ||
    (benefitDefinition?.isCostCenterMandatory && !user?.costCenter)
  )
    missingUserInfoStatus = "incomplete-profile";

  const handleSubmitMissingInfo = formMethods.handleSubmit(() => {
    if (missingUserInfoStatus === "e-ident") setIsEIdentModalOpen(true);
    else if (missingUserInfoStatus === "incomplete-profile")
      setIsMissingInfoModalOpen(true);
  });

  const leasingCost = calculation
    ? calculation.monthlyInsuranceFee * calculation.leasingPeriodMonths +
      calculation.totalFinancingCost +
      calculation.totalServiceCost
    : undefined;

  return (
    <Form
      formMethods={formMethods}
      onSubmit={missingUserInfoStatus ? handleSubmitMissingInfo : undefined}
    >
      <Flex direction="column" gap="lg">
        <ShopCard shop={shop} />
        <BikeOrderCard order={order} />
        <MaintenanceCard order={order} />
        <DownPaymentCard order={order} />
        <CancellationCoverageCard
          order={order}
          benefitDefinition={benefitDefinition}
          onModalOpen={() => setIsTermsModalOpen(true)}
        />
        <LeasingCostsCard order={order} calculation={calculation} />
        {order.state === BikeBenefitOrderState.Draft ? (
          <LeasingContractConfirmCard
            benefitDefinition={benefitDefinition}
            order={order}
            calculation={calculation}
            leasingCost={leasingCost}
            onModalOpen={() => setIsTermsModalOpen(true)}
            isLoading={mutation.isLoading}
            swedenSuggestedBenefitValues={swedenSuggestedBenefitValues}
          />
        ) : (
          <ContractSummaryCard
            benefitDefinition={benefitDefinition}
            redemptionPrice={calculation?.residualValue}
            residualRatio={calculation?.redemptionPercentage}
            initialMonthlyTaxableValue={
              order.fixedMonthlyTaxableValue ?? calculation?.monthlyTaxableValue
            }
            monthlyTaxableValue={
              order.fixedMonthlyTaxableValue ?? calculation?.monthlyTaxableValue
            }
            leasingPeriodMonths={order.leasingPeriodMonths}
            monthlyMaintenanceBudget={order.monthlyMaintenanceBudget}
            downPaymentAmount={order.fixedDownPaymentAmount}
            bikeOrderPrice={getOrderTotal(order)}
            leasingCost={leasingCost}
            swedenSuggestedBenefitValues={swedenSuggestedBenefitValues}
          />
        )}
      </Flex>
      {benefitDefinition && (
        <MissingInfoModal
          isOpen={isMissingInfoModalOpen}
          benefitDefinition={benefitDefinition}
          onClose={() => setIsMissingInfoModalOpen(false)}
        />
      )}
      {user && (
        <MissingSSNModal
          isOpen={isEIdentModalOpen}
          missingUserInfoStatus={missingUserInfoStatus}
          onClose={() => setIsEIdentModalOpen(false)}
          onOpen={() => setIsEIdentModalOpen(true)}
          onOpenMissingInfoModal={() => setIsMissingInfoModalOpen(true)}
        />
      )}
      <BenefitTermsModal
        isOpen={isTermsModalOpen}
        onClose={() => setIsTermsModalOpen(false)}
        title={benefitDefinition?.organisation.name ?? ""}
        content={benefitDefinition?.currentBenefitDefinitionTerm?.content ?? ""}
      />
    </Form>
  );
}
